<template>
  <ion-page>
    <ion-content v-if="!isLoading">
      <ion-row class="impact-section">
        <ion-col size="12" class="ixkdhkjfn">
          This is <span class="red-title" :style="'padding-left:1vw;'">your</span> impact
        </ion-col>
        <ion-col size="12" style="padding:0 5%;">
          <Causes v-if="causes" :items="causes" :type="'dashboard'" />
          <ion-spinner v-else />
        </ion-col>
      </ion-row>

      <ion-row v-if="!transactions" class="feed-area ion-align-items-center" :style="'background:#F8F8F8;padding:3vh 0;margin:2vh 0'">
        <ion-col size="12" style="text-align:center;">
          <h4 style="font-size:16px;text-transform:uppercase;font-weight:600;color:#004F7C;padding:2vh 0">Let's get Started</h4>
          <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
        </ion-col>
        <ion-col size="12" style="padding:2vh 15vw;text-align:center;" v-if="user.profile.role==='consumer'">
          <span style="font-family:'Satisfy', cursive !important;font-weight:400;font-size:25px;color:#ACACAC;">Add friends and companies so you can see their impact!</span>
          <SolidButton :text="'ADD FRIENDS'" :color="'#00777E'" :style="'margin:5vh 15% 3vh;font-weight:600'" @click="addAFriend" />
          <OutlinedButton :text="'ADD COMPANIES'" :textcolor="'#00777E'" :color="'#00777E'" :style="'margin:0 15%;font-weight:600'" @click="addACompany" />
        </ion-col>
        <ion-col size="12" style="padding:2vh 15vw;text-align:center;" v-else-if="user.profile.role==='merchant'">
          <span style="font-family:'Satisfy', cursive !important;font-weight:400;font-size:25px;color:#ACACAC;">Create impact for your customers and help change the world!</span>
          <OutlinedButton :text="'Create Impact'" :textcolor="'#00777E'" :color="'#00777E'" :style="'margin:5vh 15% 3vh;font-weight:600'" @click="createNewTransactions" />
        </ion-col>
      </ion-row>
      <ion-row v-else class="feed-area ion-align-items-center" :style="'background:#F8F8F8;padding:3vh 0;margin:2vh 0'">
        {{transactions}}
      </ion-row>

    </ion-content>
  </ion-page>
</template>

<script>
/* Vue/Vuex */
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonButton } from '@ionic/vue'

/* Components */
import Causes from '@/components/cards/Causes'
import SolidButton from '@/components/buttons/Solid'
import OutlinedButton from '@/components/buttons/Outlined'

/* Plugins */
const fb = require('@/firebase/init')

/* Classes */
import { modalService } from "@/classes/modals"
import { popoverService } from "@/classes/popovers"

export default  {
  name: 'dashboard',

  components: {
    IonContent, IonPage, IonRow, IonCol, IonButton,
    Causes, SolidButton, OutlinedButton
  },

  setup() {
    const { state } = useStore();
    const router = useRouter()
    const isLoading = computed(() => state.auth.loading);
    const user = computed(() => state.auth.user);
    const uid = computed(() => state.auth.uid);
    const tags = computed(() => state.auth.tags);
    const causes = computed(() => state.causes.causes);
    const transactions = computed(() => state.transactions.transactions);
    const notifications = computed(() => state.auth.notificationsCount);

    onMounted(async () => {
      const query = await fb.db.collection("Transactions").orderBy('created','desc').get()
      query.forEach(doc => {
        state.transactions.transactions[doc.id] = doc.data()
      })
    });

    const addAFriend = async () => {
      modalService.triggerFriendsModal()
    };

    const addACompany = async () => {
      router.push('/search')
    }

    const createNewTransactions = async () => {
      if (!tags.value.includes('terms-conditions-accepted')) { return popoverService.triggerPendingAgreement('terms and conditions') }
      else if (!tags.value.includes('service-agreement-accepted')) { return popoverService.triggerPendingAgreement('service agreement') }
      else { return modalService.triggerCreateNewTransaction() }
    }


    return {
      uid,
      user,
      causes,
      notifications,
      transactions,
      isLoading,

      addAFriend,
      addACompany,
      createNewTransactions
    };
  },

}
</script>
<style scoped>
.impact-section {
  padding: 3vh 0;
}
.impact-section .ixkdhkjfn {
  padding:2vh 0 2vh;
  text-align:center;
}

ion-spinner {
  display:block;
  margin:5% auto 2%;
  --color:rgb(172, 172, 172);
}

</style>



